<template>
  <div class="border-b border-gray-800 px-6 py-4">
    <div v-if="loading" class="flex flex-col">
      <BaseLoader class="m-auto h-5 w-5" />
    </div>
    <div v-else>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-2">
          <span class="block text-sm font-semibold text-gray-200">Pair Selection</span>
        </div>
        <div class="space-y-1 rounded border border-gray-800 bg-gray-800/25 p-4">
          <p class="text-xs text-gray-400">
            Pair <span v-if="alertData.metric.required" class="ml-1 text-xs text-red-400">{{ validationError }}</span>
          </p>
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            :options="allExchanges"
            v-model="alertData.exchange"
            :searchable="true"
            :placeholder="'Select Exchange'"
          />
          <DropdownAsyncSelect
            v-model="alertData.pair"
            dropdown-width-class="w-full"
            button-width-class="w-full"
            :placeholder="'Select a Pair'"
            :pre-selected-options="alertData.pair || []"
            :async-options="fetchExchangePairs"
          />
          <div class="flex flex-col">
            <div class="relative flex w-full items-center">
              <div class="grid w-full grid-flow-col grid-cols-9 items-center space-x-1">
                <div class="col-span-4">
                  <BaseSelect
                    dropdown-width-class="w-full"
                    button-width-class="w-full"
                    v-model="alertData.metric.id"
                    :options="[{ id: 'price', label: 'Price' }]"
                    id="metric-dropdown"
                  />
                </div>

                <div class="col-span-1">
                  <BaseSelect
                    v-model="alertData.metric.comparison"
                    dropdown-width-class="w-full"
                    button-width-class="w-full"
                    placeholder=">"
                    :options="
                      allComparisons.map(r => {
                        return { id: r.id, label: r.text };
                      })
                    "
                    class="comparison-dropdown"
                  />
                </div>

                <div class="col-span-4">
                  <BaseNumberInput class="w-full whitespace-nowrap" v-model="alertData.metric.value" required>
                    <template #left v-if="alertData.pair">
                      {{ truncate(alertData.pair.split('/')[1] == 'USD' ? '$' : alertData.pair.split('/')[1], 10) }}
                    </template>
                  </BaseNumberInput>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch, computed } from 'vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { CcxtApi } from '@/api/ccxtApi';
import DropdownAsyncSelect from '@/components/dropdown/DropdownAsyncSelect.vue';
import { truncate } from '@/composeables/filters';

const emitter = useEmitter();
const $http = useHttp();
const api = new CcxtApi();
const props = defineProps({
  alert: { type: Object, default: () => null }
});

const loading = ref(true);

//ALERT
const defaultAlertData = {
  metric: {
    id: 'price',
    name: 'Price',
    comparison: 'greater_than',
    value: null,
    type: null,
    required: false
  },
  exchange: null,
  pair: null
};
const alertData = ref(defaultAlertData);

onMounted(() => {
  emitter.$on('clear:alert', () => {
    clearAlertData();
  });
});
onBeforeUnmount(() => {
  emitter.$off('clear:alert');
});

function clearAlertData() {
  alertData.value = defaultAlertData;
}

watch(
  alertData,
  () => {
    emitter.$emit('config:alert', { config: alertData.value });
  },
  { deep: true, immediate: true }
);

//COMPARISONS
const allComparisons = ref([
  { id: 'greater_than', text: '>' },
  { id: 'less_than', text: '<' }
]);

//VALIDATION
const validationError = ref('');
function showValidationError(data) {
  if (!alertData.value.exchange) {
    alertData.value.metric.required = true;
    validationError.value = "Exchange can't be blank";
  } else if (!alertData.value.pair) {
    alertData.value.metric.required = true;
    validationError.value = "Pair can't be blank";
  } else if (!alertData.value.metric.id || !alertData.value.metric.value) {
    alertData.value.metric.required = true;
    validationError.value = "Price can't be blank";
  }
  setTimeout(function () {
    alertData.value.metric.required = false;
    validationError.value = null;
  }, 3000);
}

//Exchange
onMounted(() => {
  if (props.alert) {
    alertData.value = props.alert.config;
  }
  fetchExchanges();
  emitter.$on('PairForm', data => {
    showValidationError(data);
  });
});
onBeforeUnmount(() => {
  emitter.$off('PairForm');
});

const allExchanges = ref([]);
async function fetchExchanges() {
  const resp = await api.getAllExchanges();
  allExchanges.value = resp.map(e => {
    return { id: e.exchange, label: e.name };
  });
  loading.value = false;
}

async function fetchExchangePairs(search) {
  if (!alertData.value.exchange) {
    return;
  }
  const resp = await api.getExchangePairs({
    per_page: 100,
    my_exchanges: [alertData.value.exchange],
    search: search,
    sort_column: 'day_volume',
    sort_order: 'desc'
  });
  return resp.data.data.map(p => {
    return { id: p.pair, label: p.pair };
  });
}

watch(
  () => alertData.value.exchange,
  (newVal, oldVal) => {
    if (oldVal != newVal && oldVal != null) {
      alertData.value.pair = null;
      emitter.$emit('clear:search');
    }
    emitter.$emit('fetch:data');
  },
  { deep: true }
);
</script>
